import { SvgIcon } from "@mui/material";
import { memo } from "preact/compat";

const MenuExperienceCreatorIcon = (props) => (
  // <SvgIcon {...props}>
  //   <svg
  //     id='Menu_EC'
  //     data-name='Menu EC'
  //     xmlns='http://www.w3.org/2000/svg'
  //     width='24'
  //     height='24'
  //     viewBox='0 0 24 24'>
  //     <path id='Path_922' data-name='Path 922' d='M0,0H24V24H0Z' fill='none' />
  //     <g id='Group_1406' data-name='Group 1406' transform='translate(-432 12819)'>
  //       <path
  //         id='Ellipse_294'
  //         data-name='Ellipse 294'
  //         d='M4,1A3,3,0,1,0,7,4,3,3,0,0,0,4,1M4,0A4,4,0,1,1,0,4,4,4,0,0,1,4,0Z'
  //         transform='translate(435 -12815)'
  //         fill='#fff'
  //       />
  //       <path
  //         id='Ellipse_295'
  //         data-name='Ellipse 295'
  //         d='M4,1A3,3,0,1,0,7,4,3,3,0,0,0,4,1M4,0A4,4,0,1,1,0,4,4,4,0,0,1,4,0Z'
  //         transform='translate(445 -12815)'
  //         fill='#fff'
  //       />
  //       <path
  //         id='Ellipse_296'
  //         data-name='Ellipse 296'
  //         d='M4,1A3,3,0,1,0,7,4,3,3,0,0,0,4,1M4,0A4,4,0,1,1,0,4,4,4,0,0,1,4,0Z'
  //         transform='translate(440 -12806)'
  //         fill='#fff'
  //       />
  //     </g>
  //   </svg>
  // </SvgIcon>
  <svg
    width="35"
    height="35"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.79007 4.32813C6.1718 4.32813 5.56742 4.51147 5.05336 4.85496C4.53929 5.19845 4.13862 5.68666 3.90202 6.25786C3.66542 6.82906 3.60352 7.4576 3.72413 8.06398C3.84475 8.67036 4.14247 9.22736 4.57965 9.66454C5.01683 10.1017 5.57383 10.3994 6.18021 10.5201C6.7866 10.6407 7.41513 10.5788 7.98633 10.3422C8.55754 10.1056 9.04575 9.70491 9.38924 9.19084C9.73273 8.67677 9.91606 8.07239 9.91606 7.45413C9.91606 6.62506 9.58672 5.82995 9.00048 5.24372C8.41424 4.65748 7.61913 4.32813 6.79007 4.32813ZM6.79007 3.28613C7.61442 3.28613 8.42026 3.53058 9.10568 3.98857C9.7911 4.44655 10.3253 5.0975 10.6408 5.85911C10.9563 6.62071 11.0388 7.45875 10.878 8.26726C10.7172 9.07578 10.3202 9.81844 9.73729 10.4013C9.15438 10.9843 8.41171 11.3812 7.6032 11.542C6.79469 11.7029 5.95665 11.6203 5.19504 11.3049C4.43344 10.9894 3.78249 10.4552 3.3245 9.76974C2.86652 9.08432 2.62207 8.27848 2.62207 7.45413C2.62207 6.34871 3.0612 5.28856 3.84285 4.50691C4.6245 3.72526 5.68465 3.28613 6.79007 3.28613Z"
      fill="white"
    />
    <path
      d="M17.21 4.32813C16.5917 4.32813 15.9873 4.51147 15.4733 4.85496C14.9592 5.19845 14.5585 5.68666 14.3219 6.25786C14.0853 6.82906 14.0234 7.4576 14.1441 8.06398C14.2647 8.67036 14.5624 9.22736 14.9996 9.66454C15.4368 10.1017 15.9938 10.3994 16.6001 10.5201C17.2065 10.6407 17.8351 10.5788 18.4063 10.3422C18.9775 10.1056 19.4657 9.70491 19.8092 9.19084C20.1526 8.67677 20.336 8.07239 20.336 7.45413C20.336 6.62506 20.0066 5.82995 19.4204 5.24372C18.8342 4.65748 18.0391 4.32813 17.21 4.32813ZM17.21 3.28613C18.0343 3.28613 18.8402 3.53058 19.5256 3.98857C20.211 4.44655 20.7452 5.0975 21.0607 5.85911C21.3762 6.62071 21.4587 7.45875 21.2979 8.26726C21.1371 9.07578 20.7401 9.81844 20.1572 10.4013C19.5743 10.9843 18.8316 11.3812 18.0231 11.542C17.2146 11.7029 16.3766 11.6203 15.615 11.3049C14.8534 10.9894 14.2024 10.4552 13.7444 9.76974C13.2864 9.08432 13.042 8.27848 13.042 7.45413C13.042 6.34871 13.4811 5.28856 14.2628 4.50691C15.0444 3.72526 16.1046 3.28613 17.21 3.28613Z"
      fill="white"
    />
    <path
      d="M12 13.7061C11.3818 13.7061 10.7774 13.8894 10.2633 14.2329C9.74925 14.5764 9.34858 15.0646 9.11198 15.6358C8.87538 16.207 8.81348 16.8355 8.9341 17.4419C9.05471 18.0483 9.35244 18.6053 9.78961 19.0425C10.2268 19.4797 10.7838 19.7774 11.3902 19.898C11.9966 20.0186 12.6251 19.9567 13.1963 19.7201C13.7675 19.4835 14.2557 19.0828 14.5992 18.5688C14.9427 18.0547 15.126 17.4503 15.126 16.8321C15.126 16.003 14.7967 15.2079 14.2104 14.6216C13.6242 14.0354 12.8291 13.7061 12 13.7061ZM12 12.6641C12.8244 12.6641 13.6302 12.9085 14.3156 13.3665C15.0011 13.8245 15.5353 14.4754 15.8508 15.237C16.1662 15.9986 16.2488 16.8367 16.0879 17.6452C15.9271 18.4537 15.5302 19.1964 14.9472 19.7793C14.3643 20.3622 13.6217 20.7591 12.8132 20.92C12.0047 21.0808 11.1666 20.9983 10.405 20.6828C9.6434 20.3673 8.99245 19.8331 8.53447 19.1477C8.07648 18.4623 7.83203 17.6564 7.83203 16.8321C7.83203 15.7266 8.27116 14.6665 9.05281 13.8848C9.83446 13.1032 10.8946 12.6641 12 12.6641Z"
      fill="white"
    />
  </svg>
);

export default memo(MenuExperienceCreatorIcon);
