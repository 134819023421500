



const ContactIcon = () => {

    return(
        <svg id="Contact_us_icon" data-name="Contact us icon" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
            <path id="Path_1417" data-name="Path 1417" d="M0,0H19V19H0Z" fill="none"/>
            <path id="Path_1418" data-name="Path 1418" d="M10.08,15.792H4.573A2.1,2.1,0,0,1,2.5,13.679V5.613A2.1,2.1,0,0,1,4.573,3.5H15.587a2.1,2.1,0,0,1,2.073,2.113v6.049a.5.5,0,1,1-1,0V5.613A1.1,1.1,0,0,0,15.587,4.5H4.573A1.1,1.1,0,0,0,3.5,5.613v8.066a1.1,1.1,0,0,0,1.073,1.113H10.08a.5.5,0,0,1,0,1Z" transform="translate(-0.58 -0.917)" fill="#081d34"/>
            <path id="Path_1419" data-name="Path 1419" d="M10.08,11.339a.5.5,0,0,1-.282-.087L2.718,6.413a.5.5,0,0,1,.564-.826l6.8,4.647,6.8-4.647a.5.5,0,0,1,.564.826l-7.08,4.839A.5.5,0,0,1,10.08,11.339Z" transform="translate(-0.58 -1.28)" fill="#081d34"/>
            <path id="Path_1420" data-name="Path 1420" d="M19.839,18.5H15a.5.5,0,1,1,0-1h4.839a.5.5,0,1,1,0,1Z" transform="translate(-3.259 -3.75)" fill="#081d34"/>
            <path id="Path_1421" data-name="Path 1421" d="M18,20.339a.5.5,0,0,1-.354-.854l2.066-2.066-2.066-2.066a.5.5,0,1,1,.707-.707l2.42,2.42a.5.5,0,0,1,0,.707l-2.42,2.42A.5.5,0,0,1,18,20.339Z" transform="translate(-3.839 -3.259)" fill="#081d34"/>
        </svg>

    )
}
export default ContactIcon