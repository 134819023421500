import {
  IKappSpaceTotalResponse,
  IKappUnitSummaryResponse,
} from "../models/summary";
import { IResponse } from "../models/http";
import { getDashboardHost } from "../configs/env";

export const getKappUnitSummary = async (
  workspaceId: string,
  kappId: string,
  userId: string,
  token: string
): Promise<IKappUnitSummaryResponse> => {
  const url = `${getDashboardHost()}/companies/${workspaceId}/kapps/${kappId}/kappsummary?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const kappUnitRes: IResponse<IKappUnitSummaryResponse> = await res.json();

  if (kappUnitRes.error) {
    throw new Error(kappUnitRes.error.message);
  } else {
    return kappUnitRes.data;
  }
};

export const getKappSpaceTotal = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<IKappSpaceTotalResponse> => {
  const url = `${getDashboardHost()}/companies/${workspaceId}/kappsummary/totalbytes?userId=${userId}`;
  
  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const kappSpaceRes: IResponse<IKappSpaceTotalResponse> = await res.json();

  if (kappSpaceRes.error) {
    throw new Error(kappSpaceRes.error.message);
  } else {
    return kappSpaceRes.data;
  }
};
