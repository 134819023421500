import {getAiHost, getDashboardHost} from "../configs/env";
import { IResponse } from "../models/http";
import {
  SubmitSharepointAuthCodeRequest,
  SubmitSharepointAuthCodeResponse,
  GetSharepointAuthUrlRequest,
  GetSharepointAuthUrlResponse,
  GetSharepointAccessTokenResponse,
} from "../models/sharepoint";

//const host = "http://localhost:1234";
const host = getDashboardHost();

//013UC4ECGQ4HU423XGBBGLQ3KK26IRZARS
//013UC4ECGQ4HU423XGBBGLQ3KK26IRZARS

export const reimportSharepointFile = async ({
  workspaceId,
  kappId,
  token,
  userId,
}: {
  workspaceId: string;
  kappId: string;
  userId: string;
  token: string;
}) => {
  const url = `${getAiHost()}/sharepoint/reimport`;
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      workspace_id: workspaceId,
      kapp_id: kappId,
      user_id: userId,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const result = await response.json();
  return result;
};

export const downloadFromSharepoint = async ({
  userId,
  workspaceId,
  files,
  kappId,
  sharepointEndpoint,
  token,
}: {
  kappId: string;
  sharepointEndpoint: string;
  userId: string;
  workspaceId: string;
  files: { id: string; name: string; folder?: unknown, parentReference: {driveId: string} }[];
  token: string;
}): Promise<boolean> => {
  const url = new URL(`${getAiHost()}/sharepoint/files`);

  const reqObj = {
    sharepointEndpoint,
    userId,
    workspaceId,
    kappId,
    files,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const userResp: boolean = await res.json();

  return userResp;
};

export const getSharepointAuthUrl = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<GetSharepointAuthUrlResponse> => {
  const url = new URL(
    `${getAiHost()}/sharepoint/oauth/link?userId=${userId}&workspaceId=${workspaceId}`
  );

  const reqObj: GetSharepointAuthUrlRequest = {
    workspaceId,
    userId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: GetSharepointAuthUrlResponse = await res.json();

  return userResp;
};

export const getSharepointAccessToken = async ({
  workspaceId,
  userId,
  token,
}: {
  workspaceId: string;
  userId: string;
  token: string;
}): Promise<GetSharepointAccessTokenResponse> => {
  const url = new URL(
    `${getAiHost()}/sharepoint/oauth/access_token?userId=${userId}&workspaceId=${workspaceId}`
  );

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  if (res.status === 410) {
    //throw a new error with e.response.status = 410

    throw new Error("gone");
  }

  if (res.status !== 200) {
    throw new Error("Failed to get access token");
  }

  const userResp: GetSharepointAccessTokenResponse =
    await res.json();

  return userResp
};

export const submitSharepointOAuthCode = async ({
  workspaceId,
  code,
  userId,
  token,
}: {
  workspaceId: string;
  code: string;
  userId: string;
  token: string;
}): Promise<SubmitSharepointAuthCodeResponse> => {
  const url = new URL(`${getAiHost()}/sharepoint/oauth/code`);

  const reqObj: SubmitSharepointAuthCodeRequest = {
    workspaceId,
    userId,
    code,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const userResp: SubmitSharepointAuthCodeResponse =
    await res.json();

  return userResp;
};
