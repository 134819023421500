

export enum LoadingProgressStatus {
    "INIT",
    "PLATFORM_LOADED",
    "AUTH2_LOADED",
    "GOOGLE_AUTH_INITILIZED",
    "USER_COMPANY_LOADED",
    "LOAD_PAGE_DATA",
    "DONE"
  }