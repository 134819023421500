import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { getMonthlyTotalQueries, getTotalQueriesAllTime, getTotalQueries } from "../api/stats-api";
import { getKappSpaceTotal } from "../api/kapp-summary-api";
import { useUserAuthContext } from "./UserAuthContext";
import { monthlyQueriesLimit, totalBytesLimit } from "../const";
import { getWorkspaseAliasById } from "../api/alias-api";
import { Roles } from "../models/roles";
import { getRole } from "../api/roles-api";
import { getWorkspaceById,getAdvancedIngestionStatus } from "../api/workspace-api";
import { getAiHost,getDashboardHost } from "../configs/env";
import {getSharepointAccessToken} from "../api/sharepoint-api";
import { getPaymentStatus, PurchaseStatus } from "../api/payments-api";
import {isEnterprisePlan} from "../utils";

export interface IWorkspaceContext {
  workspaceId: string;
  totalBytes: number;
  plan: string;
  sources: PurchaseStatus["sources"];
  experiences: PurchaseStatus["experiences"];
  planDisplayName: string;
  maxTotalBytes: number;
  maxMonthlyQueries: number;
  monthlyQueries: number;
  totalQueries: number;
  setLoadLimitBarData: (bool: boolean) => void;
  showSharepointWarning: boolean;
  passMaxLimit: boolean;
  setPassMaxLimit: (bool: boolean) => void;
  workspaceAlias: string;
  setLoadAliasName: (bool: boolean) => void;
  currentUserRole: Roles;
  isPaying:boolean;
  sasToken:string;
  MultipleLanguages:boolean;
  sasTokenTimestamp:number;
  updateSasToken: () => void;
  isSasTokenExpired: (timestamp: number) => boolean;
  underMaintance:boolean;
  advancedIngestion:boolean;
}

export const WorkspaceContext = createContext<IWorkspaceContext>(null);

export const useWorkspaceContext = () => {
  return useContext(WorkspaceContext);
};

interface IProps {
  workspaceId: string;
}

const WorkspaceProvider: FunctionalComponent<IProps> = ({
  workspaceId,
  children,
}) => {
  const [totalBytes, setTotalBytes] = useState<number>(0);
  const [sources, setSources] = useState<PurchaseStatus["sources"]>({
    file: false,
    youtube: false,
    dropbox: false,
    google_drive: false,
    google_drive_sync: false,
    onedrive_sync: false,
  })
  const [experiences, setExperiences] = useState<PurchaseStatus["experiences"]>({
    max_experiences: 10,
    search_portal: false,
    chatbot: false,
    iframe: false,
    widget: false,
    sdk: false,
  })
  const [plan, setPlan] = useState<string>("");
  const [planDisplayName, setPlanDisplayName] = useState<string>("");
  const [maxTotalBytes, setMaxTotalBytes] = useState<number>(0);
  const [maxMonthlyQueries, setMaxMonthlyQueries] = useState<number>(0);
  const [monthlyQueries, setMonthlyQueries] = useState<number>(0);
  const [totalQueries, setTotalQueries] = useState<number>(0);
  const [showSharepointWarning, setShowSharepointWarning] = useState<boolean>(false);
  const [loadLimitBarData, setLoadLimitBarData] = useState<boolean>(true);
  const [passMaxLimit, setPassMaxLimit] = useState<boolean>(false);
  const [workspaceAlias, setWorkspaceAlias] = useState<string>("");
  const [loadAliasName, setLoadAliasName] = useState<boolean>(true);
  const [currentUserRole, setCurrentUserRole] = useState<Roles>();
  const [isPaying, setIsPaying] = useState<boolean | null>(null);
  const [sasToken, setSasToken] = useState<string>("");
  const [sasTokenTimestamp, setSasTokenTimestamp] = useState<number>(0);
  const [MultipleLanguages, setMultipleLanguages] = useState<boolean | null>(null);
  const [underMaintance, setUnderMaintance] = useState<boolean>(true);
  const [advancedIngestion, setAdvancedIngestion] = useState<boolean>(false);


  const { userDetails, isKorraUser } = useUserAuthContext();

  useEffect(() => {
    const fetch = async () => {
      const roleItem = await getRole(
        workspaceId,
        userDetails.userId,
        userDetails.userId,
        userDetails.token
      );
      setCurrentUserRole(roleItem.role)
    };

      if (userDetails?.workspaceId && userDetails?.workspaceId !== workspaceId && !isKorraUser) {
        fetch();
      }
  }, [userDetails?.workspaceId]);

  useEffect(() => {
    const fetch = async () => {
      try {
        await getSharepointAccessToken({ workspaceId, userId: userDetails.userId, token: userDetails.token });
      } catch(e: any) {
        //see if message = "gone"
        //if 404, ignore, if 410, show warning
        if (e.message === "gone") {
          setShowSharepointWarning(true);
        }
      }
    };
    fetch();
  }, [workspaceId]);

  useEffect(() => {
    const fetch = async () => {
      const workspace = await getWorkspaceById(
        workspaceId,
        userDetails.userId,
        userDetails.token
      );

      setIsPaying(!!workspace?.settings?.isPaying)
      setMultipleLanguages(workspace?.settings?.MultipleLanguages)
    };
    fetch();
  }, [workspaceId]);

  useEffect(() => {
    const fetch = async () => {
      const userId = userDetails.userId;

      try {
        const { plan, plan_display_name, limits, sources } = await getPaymentStatus(
          workspaceId,
          userId,
          userDetails?.token
        );
        setSources(sources);
        setPlan(plan);
        setExperiences(experiences);
        setPlanDisplayName(plan_display_name);
        setMaxTotalBytes(limits.storage);
        setMaxMonthlyQueries(limits.queries);
      } catch (err) {
        console.error(err);
      }

      try {
        const { totalSizeInBytes } = await getKappSpaceTotal(
          workspaceId,
          userId,
          userDetails?.token
        );
        setTotalBytes(totalSizeInBytes);
      } catch (err) {
        console.error(err);
      }

      try {
        const monthQueries = await getMonthlyTotalQueries(
          workspaceId,
          userId,
          userDetails?.token
        );
        setMonthlyQueries(monthQueries);
      } catch (err) {
        console.error(err);
      }

      try {
        let total = await getTotalQueries(
          workspaceId,
          userId,
          userDetails?.token
        );

        setTotalQueries(Math.max(total, monthlyQueries));
      } catch (err) {
        console.error(err);
      }
    };

    if (
      loadLimitBarData &&
      workspaceId &&
      userDetails?.userId &&
      userDetails?.token                   
    ) {
      fetch();
      setLoadLimitBarData(false);
    }
  }, [loadLimitBarData, workspaceId, userDetails?.userId, userDetails?.token]);

  //check if need to show limit bar
  useEffect(() => {
    if ((totalBytes >= totalBytesLimit || monthlyQueries > monthlyQueriesLimit) && !isEnterprisePlan(plan) && plan !== "business_plus") {       
      setPassMaxLimit(true);    
    } else {
      setPassMaxLimit(false);
    }
  }, [totalBytes, monthlyQueries, plan]);

  //check if need to show limit bar
  useEffect(() => {
    const fetch = async () => {
      const userId = userDetails.userId;

      try {
        const name = await getWorkspaseAliasById(
          workspaceId,
          userId,
          userDetails?.token
        );
        setWorkspaceAlias(name);
      } catch (err) {
        console.error(err);
      }
    };

    if (workspaceId && userDetails?.userId && userDetails?.token) {
      fetch();
      setLoadAliasName(false);
    }
  }, [loadAliasName, workspaceId, userDetails?.userId, userDetails?.token]);

  useEffect(() => {
    const fetch = async () => {
      await updateSasToken();
    };
    fetch();
  }, [workspaceId]);

  useEffect(() => {
    if (sasTokenTimestamp && isSasTokenExpired(sasTokenTimestamp)) {
      console.log(`sasToken expired, updating it...`);
      const fetch = async () => {
        await updateSasToken();
      };
      fetch();
    }
  });

  useEffect(() => {
    
      const fetch = async () => {
        await getWorkspaceVersion();
      };
      fetch();
    
  },[]);

  const getWorkspaceVersion = async () => {
    const axios = require("axios").default;
    const workspaceVersionResponse = await axios(`${getAiHost()}/api/elastic_migration/status/${workspaceId}`,
    { headers: {
      Authorization: `Bearer ${userDetails?.token}`,
      'User-Id':userDetails.userId,}}
    );
      if(workspaceVersionResponse && workspaceVersionResponse?.status === 200) {
      setUnderMaintance(workspaceVersionResponse?.data?.status === "v1-v2" ? true : false);
    }
    else{
      console.error("Cant get workspace status")
    }
  }

  const isSasTokenExpired = (timestamp: number) => {
    const now = new Date().getTime();
    const diff = now - timestamp;
    const minutes = Math.floor(diff / 1000 / 60);
    return minutes > 5;
  }

  const updateSasToken = async () => {
    const axios = require("axios").default;
    const sasTokenResponse = await axios(`${getDashboardHost()}/flow/cdnuploader/st/workspace/${workspaceId}?userId=${userDetails.userId}`,
    { headers: {
      Authorization: `Bearer ${userDetails?.token}`,
      'User-Id':userDetails.userId,'Portal-Id': "",'Mode':"kapp"}}
    );
      if(sasTokenResponse) {
      setSasToken(sasTokenResponse?.data?.data?.sasToken);
      setSasTokenTimestamp(new Date().getTime());
    }
  }


  useEffect(() => {
    const fetch = async () => {
      const res = await getAdvancedIngestionStatus(
        workspaceId,
        userDetails.userId,
        userDetails.token
      );
      setAdvancedIngestion(res === "on"? true:false)
    };
    fetch();
  }, [workspaceId]);

  const kappApi: IWorkspaceContext = {
    showSharepointWarning,
    plan,
    sources,
    planDisplayName,
    workspaceId,
    maxTotalBytes,
    maxMonthlyQueries,
    totalBytes,
    monthlyQueries,
    totalQueries,
    setLoadLimitBarData,
    passMaxLimit,
    setPassMaxLimit,
    workspaceAlias,
    setLoadAliasName,
    currentUserRole,
    isPaying,
    sasToken,
    experiences,
    sasTokenTimestamp,
    updateSasToken,
    isSasTokenExpired,
    MultipleLanguages,
    underMaintance,
    advancedIngestion,
  };

  return (
    <WorkspaceContext.Provider value={kappApi}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;
