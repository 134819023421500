import { h } from "preact";
import styles from "./styles.scss";

interface IProps {
    text?: string;
}

const Spinner = ({ text }: IProps) => {
    return (
        <div className={styles.loaderWrapper}>
            <div className={styles.loader}></div>
            <div className={styles.text}>{text}</div>
            <div
                style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "lightgray",
                    fontSize: "10px",
                }}
            >
            </div>
        </div>
    );
};
export const SmallSpinner = () => {
    return (
        <div className={styles.smallLoaderWrapper}>
            <div className={styles.loader}></div>
        </div>
    );
};
export default Spinner;
