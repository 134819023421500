import { h } from 'preact'
import React from 'react'
import styles from './styles.module.scss'

const Content = ({ height, children }:any) => {
  return (
    <main className={styles.content} style={{ height, maxHeight: height }}>
      {children}
    </main>
  )
}

export default Content
