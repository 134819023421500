


const CloseIcon = ({white}) => {

    return(
        <svg id="Group_1547" data-name="Group 1547" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_1268" data-name="Path 1268" d="M0,0H24V24H0Z" fill="none"/>
            <path id="Line_192" data-name="Line 192" d="M0,12.5a.5.5,0,0,1-.354-.146.5.5,0,0,1,0-.707l12-12a.5.5,0,0,1,.707,0,.5.5,0,0,1,0,.707l-12,12A.5.5,0,0,1,0,12.5Z" transform="translate(6 6)" fill={white?'white':"#081d34"}/>
            <path id="Line_193" data-name="Line 193" d="M12,12.5a.5.5,0,0,1-.354-.146l-12-12a.5.5,0,0,1,0-.707.5.5,0,0,1,.707,0l12,12A.5.5,0,0,1,12,12.5Z" transform="translate(6 6)" fill={white?'white':"#081d34"}/>
        </svg>

    )
}
export default CloseIcon






