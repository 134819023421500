import { getAuthHost } from "../configs/env";
import { IResponse, IResponseError } from "../models/http";
import { IUser, UpdateUserRequest } from "../models/user";
import { getAuth0ClientId } from "../configs/env";

export const getUserByEmail = async (
  email: string,
  googleJWT: string
): Promise<IUser | null> => {
  const url = `${getAuthHost()}/users/email/${email}`;

  // the origin looks like this: editor.X.korra.ai. extract the X part using regex
  const origin = window.location.origin;
  const match = origin.match(/editor\.(.*)\.korra\.ai/);
  const auth0App = match ? match[1] : "";
  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${googleJWT}`,
      "Client-Id": getAuth0ClientId(),
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUser> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

export const getUserById = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<IUser | null> => {
  const url = `${getAuthHost()}/users/${userId}/workspaces/${workspaceId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUser> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

export const updateUser = async (
  workspaceId: string,
  userId: string,
  secondEmail: string,
  phone: string,
  firstName: string,
  lastName: string,
  token: string
): Promise<IUser> => {
  var url = new URL(`${getAuthHost()}/users/${userId}`);

  const reqObj: UpdateUserRequest = {
    workspaceId,
    userId,
    secondEmail,
    phone,
    firstName,
    lastName,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<IUser> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const registerIdentityUser = async (
  userEmail: string,
  password: string,
  token: string
) => {
  const url = `${getAuthHost()}/identity/register`;
  // POST http://localhost:1235/identity/register -H "Content-Type: application/x-www-form-urlencoded"
  // 																				-d "email=lior@korra.ai&password=123456"

  const reqObj = {
    password: password,
    email: userEmail,
  };
  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });
};

export const creatNewUser = async (
  firstName: string,
  lastName: string,
  name: string,
  email: string,
  googleJWT: string
): Promise<IUser> => {
  let url = `${getAuthHost()}/users`;

  if (!googleJWT) {
    url = `${getAuthHost()}/users/identity`;
  }

  const reqObj = {
    firstName,
    lastName,
    name,
    email,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${googleJWT}`,
      "Client-Id": getAuth0ClientId(),
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const userResp: IResponse<IUser> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

export const deleteUser = async (
  userId: string,
  workspaceId: string,
  token: string
): Promise<boolean> => {
  const url = `${getAuthHost()}/users/${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "DELETE",
    mode: "cors",
    body: JSON.stringify({ userId, workspaceId }),
  });

  return res.status === 200;
};
