import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useState } from "preact/hooks";

export interface SelectedKappContextActions {
  handleAddKappId: (id: string) => void;
  selectedKappId: string;
}

export const SelectedKappContext =
  createContext<SelectedKappContextActions>(null);

export const useSelectedKappContext = () => {
  return useContext(SelectedKappContext);
};

const SelectedKappProvider: FunctionalComponent = ({ children }) => {
  const [selectedKappId, setSelectedKappId] = useState<any>();

  const handleAddKappId = (id: string) => {
    setSelectedKappId(id);
  };

  return (
    <SelectedKappContext.Provider
      value={{
        selectedKappId: selectedKappId,
        handleAddKappId: handleAddKappId,
      }}
    >
      {children}
    </SelectedKappContext.Provider>
  );
};

export default SelectedKappProvider;
