


const LogoutIcon = () => {

    return(
        <svg id="Log_out_icon" data-name="Log out icon" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
            <path id="Path_1412" data-name="Path 1412" d="M0,0H19V19H0Z" fill="none"/>
            <path id="Path_1413" data-name="Path 1413" d="M10.25,17.055H4.611A2.093,2.093,0,0,1,2.5,14.986V5.569A2.093,2.093,0,0,1,4.611,3.5H10.25a2.093,2.093,0,0,1,2.111,2.069V7.139a.5.5,0,0,1-1,0V5.569A1.092,1.092,0,0,0,10.25,4.5H4.611A1.092,1.092,0,0,0,3.5,5.569v9.417a1.092,1.092,0,0,0,1.111,1.069H10.25a1.092,1.092,0,0,0,1.111-1.069V13.417a.5.5,0,0,1,1,0v1.569A2.093,2.093,0,0,1,10.25,17.055Z" transform="translate(-0.583 -0.778)" fill="#081d34"/>
            <path id="Path_1414" data-name="Path 1414" d="M15.861,14.333a.5.5,0,0,1-.354-.854l2.063-2.063L15.508,9.354a.5.5,0,0,1,.707-.707l2.417,2.417a.5.5,0,0,1,0,.707l-2.417,2.417A.5.5,0,0,1,15.861,14.333Z" transform="translate(-1.695 -1.917)" fill="#081d34"/>
        </svg>

    )
}
export default LogoutIcon