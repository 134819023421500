import { getAuthHost } from "../configs/env";
import { IResponse } from "../models/http";
import { RoleItem, Roles, UpdateRoleRequest } from "../models/roles";

export const getRole = async (
  workspaceId: string,
  userIdToGet: string,
  userId: string,
  token: string
): Promise<RoleItem> => {
  const url = `${getAuthHost()}/role/workspaces/${workspaceId}/users/${userIdToGet}?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const roleItem: IResponse<RoleItem> = await res.json();

  if (roleItem.error) {
    throw new Error(roleItem.error.message);
  } else {
    return roleItem.data;
  }
};

export const updateRole = async (
  id: string,
  workspaceId: string,
  role: Roles,
  userId: string,
  token: string
): Promise<RoleItem> => {
  var url = new URL(`${getAuthHost()}/role/{id}`);

  const reqObj: UpdateRoleRequest = {
    id,
    workspaceId,
    userId,
    role,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<RoleItem> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};
