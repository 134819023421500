import { FunctionalComponent, h, Fragment } from "preact";
import { Router, Route, useRouter } from "preact-router";
import { useEffect, useState } from "preact/hooks";

import { useUserAuthContext } from "../../context/UserAuthContext";

import WorkspaceProvider from "../../context/WorkspaceContext";
import FirstLogin from "../FirstLogin/FirstLogin";
import { isMobile } from "react-device-detect";
import Layout from "../layout";

interface IProps {
  path: string;
  component: preact.FunctionalComponent<any>;
}

const Protected: FunctionalComponent<IProps> = ({ path, component }) => {
  const routerContext = useRouter();
  const { isAuth, isFirstLogin } = useUserAuthContext();

  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>("");

  useEffect(() => {
    const currentMatch = routerContext[0].matches;
    const currentId = currentMatch?.workspaceId;
    setCurrentWorkspaceId(currentId);
  }, [routerContext[0].matches]);

  // console.log({
  //   isFirstLogin,
  //   isAuth,
  //   isMobile,
  //   currentWorkspaceId,
  // })

  return (
    <Fragment>
      {/* {isFirstLogin && isMobile ? (
        <FirstLogin />
      ) : */}
      {currentWorkspaceId && isAuth ? (
        <WorkspaceProvider workspaceId={currentWorkspaceId}>
          {path.includes("/segmentation") ? (
            <Router>
              <Route path={path} component={component} />
            </Router>
          ) : (
            <Layout>
              <Router>
                <Route path={path} component={component} />
              </Router>
            </Layout>
          )}

          {/* {isFirstLogin && !isMobile ? <FirstLogin /> : null} */}
        </WorkspaceProvider>
      ) : null}
      {/* ) : null} */}
    </Fragment>
  );
};

export default Protected;
