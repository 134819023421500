import { getAiHost } from "../configs/env";
import { IResponse } from "../models/http";

//const host = "http://localhost:8000"; //getAiHost()
const host = getAiHost();

interface BillingURL {
  billing_url?: string;
}

export const updateIsPayingWorkspace = async (
  workspaceId: string,
  isPaying: boolean,
  token: string
): Promise<BillingURL> => {
  const url = `${host}/payments/grant`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      workspaceId,
      isPaying,
    }),
    method: "POST",
    mode: "cors",
  });

  const response: BillingURL = await res.json();

  return response;
};

export interface PurchaseStatus {
  plan: string;
  plan_display_name: string;
  limits: {
    queries: number;
    storage: number;
    files: number;
  };
  sources: {
    file: boolean;
    youtube: boolean;
    dropbox: boolean;
    google_drive: boolean;
    google_drive_sync: boolean;
    onedrive_sync: boolean;
  },
  experiences: {
    max_experiences: number | null;
    search_portal: boolean;
    chatbot: boolean;
    iframe: boolean;
    widget: boolean;
    sdk: boolean;
  }
}

export const getBillingUrl = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<BillingURL> => {
  const url = `${host}/payments/billing?company_id=${workspaceId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const response: BillingURL = await res.json();

  return response;
};

export const getPaymentStatus = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<PurchaseStatus> => {
  const url = `${host}/payments/status?company_id=${workspaceId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const response: IResponse<PurchaseStatus> = await res.json();

  if (response.error) {
    throw new Error(response.error.message);
  } else {
    return response.data;
  }
};
