import { IResponse } from "../models/http";
import {
  IStatsItemResponse,
  ITopFileTypes,
  UserLogEvent,
} from "../models/stats";
import { getStatsHost, getEditorHost, getDashboardHost, getAiHost } from "../configs/env";

export const LogUserEvent = async (
  workspaceId: string,
  KappId: string,
  UserId: string,
  UserEmail:string,
  EventType: string,
  Description: string,
  token: string,
): Promise<UserLogEvent> => {
  const validateEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      UserEmail
    );

  if (!validateEmail) {
    throw new Error("Invalid UserEmail");
  }

  if(workspaceId.length !== 24)
  {
    throw new Error("Invalid workspaceId");
  }

  if(KappId !=='N/A' && KappId.length !== 24)
  {
    throw new Error("Invalid KappId");
  }

  var url = new URL(`${getStatsHost()}/stats/editor/UserLog`);

  const reqObj: UserLogEvent = {
    workspaceId,
    KappId,
    UserId,
    UserEmail,
    EventType,
    Description,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<UserLogEvent> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const getWorkspaceLog = async (
  workspaceId: string,
  userId: string,
  email:string,
  token: string
): Promise<UserLogEvent[]> => {

  const validateEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      email
    );

  if (!validateEmail) {
    throw new Error("Invalid UserId");
  }

  if(workspaceId.length !== 24)
  {
    throw new Error("Invalid workspaceId");
  }

  var url = new URL(
    `${getStatsHost()}/stats/editor/workspace/${workspaceId}?userId=${userId}&userEmail=${email}`
  );

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

    method: "GET",
    mode: "cors",
  });
  const getResp: IResponse<UserLogEvent[]> = await res.json();
  if (getResp.error) {
    throw new Error(getResp.error.message);
  } else {
    return getResp.data;
  }
};

export const getUserLog = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<UserLogEvent[]> => {

  const validateEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      userId
    );

  if (!validateEmail) {
    throw new Error("Invalid UserId");
  }

  if(workspaceId.length !== 24)
  {
    throw new Error("Invalid workspaceId");
  }

  

  var url = new URL(
    `${getStatsHost()}/stats/editor/workspace/${workspaceId}/userId/${userId}`
  );

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

    method: "GET",
    mode: "cors",
  });

  const getResp: IResponse<UserLogEvent[]> = await res.json();

  if (getResp.error) {
    throw new Error(getResp.error.message);
  } else {
    return getResp.data;
  }
};

export const getStatsList = async (
  workspaceId: string,
  kappId: string,
  token: string,
  userId: string
): Promise<IStatsItemResponse[]> => {
  const url = `${getStatsHost()}/stats/web-app/workspace/${workspaceId}/kapps/${kappId}?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const statsList: IResponse<IStatsItemResponse[]> = await res.json();

  if (statsList.error) {
    throw new Error(statsList.error.message);
  } else {
    return statsList.data;
  }
};

export const getStats = async (
  workspaceId: string,
  filter: string,
  token: string,
  userId: string
): Promise<IStatsItemResponse[]> => {
  const url = `${getStatsHost()}/stats/web-app/workspace/${workspaceId}?userId=${userId}&filter=${filter}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const statsList: IResponse<IStatsItemResponse[]> = await res.json();

  if (statsList.error) {
    throw new Error(statsList.error.message);
  } else {
    return statsList.data;
  }
};

export const getAllStats = async (
  workspaceId: string,
  filter: string,
  token: string,
  userId: string
): Promise<IStatsItemResponse[]> => {
  const url = `${getStatsHost()}/stats/web-app/all/workspace/${workspaceId}?userId=${userId}&filter=${filter}`;
  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });
  const statsList: IResponse<IStatsItemResponse[]> = await res.json();
  if (statsList.error) {
    throw new Error(statsList.error.message);
  } else {
    return statsList.data;
  }
};

export const getMonthlyStats = async (
  workspaceId: string,
  filter: string,
  token: string,
  userId: string
): Promise<IStatsItemResponse[]> => {
  try {
    const url = `${getStatsHost()}/stats/web-app/monthly-stats/workspace/${workspaceId}?userId=${userId}&filter=${filter}`;
    const res = await fetch(<any>url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
      mode: "cors",
    });
    const statsList: IResponse<IStatsItemResponse[]> = await res.json();
    return statsList.data;
  } catch (error: any) {
    console.error(error?.message);
    return [];
  }
};

export const getWeeklyStats = async (
  workspaceId: string,
  filter: string,
  token: string,
  userId: string
): Promise<IStatsItemResponse[]> => {
  try {
    const res = await fetch(
      <any>(
        `${getStatsHost()}/stats/web-app/weekly-stats/workspace/${workspaceId}?userId=${userId}&filter=${filter}`
      ),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "GET",
        mode: "cors",
      }
    );
    const statsList: IResponse<IStatsItemResponse[]> = await res.json();
    return statsList.data;
  } catch (error: any) {
    console.error(error?.message);
    return [];
  }
};

export const getTotalQueries = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<number> => {
  const url = `${getStatsHost()}/stats/web-app/total-queries/workspace/${workspaceId}?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const totalQueries: IResponse<number> = await res.json();

  if (totalQueries.error) {
    throw new Error(totalQueries.error.message);
  } else {
    return totalQueries.data;
  }
};

export const getMonthlyTotalQueries = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<number> => {
  //const url = `${getStatsHost()}/stats/web-app/monthly-counter/workspace/${workspaceId}?userId=${userId}`;
  const url = `${getAiHost()}/query-limit/counter?company_id=${workspaceId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const totalQueries: IResponse<{counter: number}> = await res.json();

  if (totalQueries.error) {
    throw new Error(totalQueries.error.message);
  } else {
    return totalQueries.data.counter;
  }
};

export const getTopFileTypes = async (
  workspaceId: string,
  userId: string,
  token: string,
  filter: string
): Promise<ITopFileTypes> => {
  const url = `${getStatsHost()}/stats/web-app/top-file-types/workspace/${workspaceId}?userId=${userId}&filter=${filter}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const topFileTypes: IResponse<ITopFileTypes> = await res.json();

  if (topFileTypes.error) {
    throw new Error(topFileTypes.error.message);
  } else {
    return topFileTypes.data;
  }
};

export const getTotalQueriesAllTime = async (
  workspaceId: string,
  token: string
): Promise<number> => {
  const url = `${getAiHost()}/query-limit/total-queries?company_id=${workspaceId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const response: IResponse<{ total_queries: number }> = await res.json();

  if (response.error) {
    throw new Error(response.error.message);
  } else {
    return response.data.total_queries;
  }
};
