import { h } from "preact";
import styles from "./questions.module.scss";
import { useState } from "preact/hooks";

const QuestionsPage = ({ open, onClose }: any) => {
  const [file, setFile] = useState();

  const fileReader = new FileReader();

  const handleOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
      };

      fileReader.readAsText(file);
    }
  };

  return (
    <div>
      Upload QNA
      {/* File Uploader */}
      <form>
        <div className={styles.page}>
          <div className={styles.input}>
            <input
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            />
          </div>
          <div className={styles.button}>
            <button
              onClick={(e) => {
                handleOnSubmit(e);
              }}
            >
              Upload CSV
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuestionsPage;
