import { h } from "preact";
import { Box, Button, Modal } from "@mui/material";
import styles from "./styles.module.scss";
import CloseIcon from "../../assets/svg/CloseIcon";

interface IProps {
  close: any;
  isVisibleModal: boolean;
  children: any;
  className?: any;
}

const ModalWindow = ({
  children,
  isVisibleModal,
  className,
  close,
}: IProps) => {
  return (
    <Modal open={isVisibleModal} disableAutoFocus sx={{ borderRadius: "16px" }}>
      <Box className={`${styles.modal} ${className}`}>
        <div className={styles.modal__close} onClick={close}>
          <CloseIcon white={false} />
        </div>
        {children}
      </Box>
    </Modal>
  );
};

export default ModalWindow;
