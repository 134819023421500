


const Question = () => {

    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
            <g id="question_icon" data-name="question icon" transform="translate(-8 -8.5)">
                <g id="Group_1581" data-name="Group 1581" transform="translate(8 8.5)">
                    <path id="Path_1415" data-name="Path 1415" d="M0,0H19V19H0Z" fill="none"/>
                    <path id="Ellipse_321" data-name="Ellipse 321" d="M7.255-.5A7.755,7.755,0,1,1-.5,7.255,7.764,7.764,0,0,1,7.255-.5Zm0,14.51A6.755,6.755,0,1,0,.5,7.255,6.763,6.763,0,0,0,7.255,14.01Z" transform="translate(2 2.49)" fill="#081d34"/>
                </g>
                <g id="Group_1588" data-name="Group 1588" transform="translate(11.049 12.031)">
                    <path id="Path_1422" data-name="Path 1422" d="M0,0H11.969V11.969H0Z" fill="none"/>
                    <path id="Path_1423" data-name="Path 1423" d="M9.995,10.487a.5.5,0,0,1-.5-.479A2.559,2.559,0,0,1,10.78,7.54l.046-.019a1,1,0,0,0,.664-1q0-.014,0-.028a1.143,1.143,0,0,0-1.246-1h-.5A1.143,1.143,0,0,0,8.5,6.5a.5.5,0,0,1-1,0,2.134,2.134,0,0,1,2.246-2h.5A2.136,2.136,0,0,1,12.49,6.482a2.006,2.006,0,0,1-1.3,1.972,1.626,1.626,0,0,0-.692,1.512.5.5,0,0,1-.478.521Z" transform="translate(-4.01 -2.506)" fill="#081d34"/>
                    <path id="Line_212" data-name="Line 212" transform="translate(6 9.969)" fill="#081d34"/>
                </g>
            </g>
        </svg>

    )
}
export default Question