import { SvgIcon } from "@mui/material";
import { memo } from "preact/compat";

const MenuAiTrainingIcon = (props) => (
  // <SvgIcon {...props}>
  //   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
  //     <g id="AI_icon" data-name="AI icon" transform="translate(-26 -333)">
  //       <circle id="Ellipse_404" data-name="Ellipse 404" cx="3" cy="3" r="3" transform="translate(26 344)" fill="#fff"/>
  //       <path id="Ellipse_404_-_Outline" data-name="Ellipse 404 - Outline" d="M3,1A2,2,0,1,0,5,3,2,2,0,0,0,3,1M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(26 344)" fill="#fff"/>
  //       <path id="Ellipse_405" data-name="Ellipse 405" d="M3,1A2,2,0,1,0,5,3,2,2,0,0,0,3,1M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(26 333)" fill="#fff"/>
  //       <path id="Ellipse_406" data-name="Ellipse 406" d="M3,1A2,2,0,1,0,5,3,2,2,0,0,0,3,1M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(40 344)" fill="#fff"/>
  //       <circle id="Ellipse_407" data-name="Ellipse 407" cx="3" cy="3" r="3" transform="translate(40 333)" fill="#fff"/>
  //       <path id="Ellipse_407_-_Outline" data-name="Ellipse 407 - Outline" d="M3,1A2,2,0,1,0,5,3,2,2,0,0,0,3,1M3,0A3,3,0,1,1,0,3,3,3,0,0,1,3,0Z" transform="translate(40 333)" fill="#fff"/>
  //       <path id="Path_1957" data-name="Path 1957" d="M32.361,347.786v-1c1.653,0,2.963-2.928,4.118-5.511,1.278-2.857,2.484-5.556,4.431-5.556v1c-1.3,0-2.427,2.524-3.518,4.964C36.05,344.683,34.663,347.786,32.361,347.786Z" transform="translate(-0.5)" fill="#fff"/>
  //     </g>
  //   </svg>
  // </SvgIcon>
  <svg
    width="35"
    height="35"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.51378 20.0002C7.0453 20.0002 8.28684 18.7587 8.28684 17.2272C8.28684 15.6956 7.0453 14.4541 5.51378 14.4541C3.98226 14.4541 2.74072 15.6956 2.74072 17.2272C2.74072 18.7587 3.98226 20.0002 5.51378 20.0002Z"
      fill="white"
    />
    <path
      d="M5.51378 15.3785C5.14814 15.3785 4.79071 15.4869 4.48669 15.69C4.18268 15.8932 3.94572 16.1819 3.8058 16.5197C3.66588 16.8575 3.62926 17.2292 3.7006 17.5878C3.77193 17.9464 3.948 18.2758 4.20655 18.5344C4.46509 18.7929 4.7945 18.969 5.15312 19.0403C5.51173 19.1117 5.88344 19.0751 6.22125 18.9351C6.55905 18.7952 6.84778 18.5583 7.05092 18.2542C7.25406 17.9502 7.36248 17.5928 7.36248 17.2272C7.36248 16.7369 7.16771 16.2666 6.82101 15.9199C6.47431 15.5732 6.00409 15.3785 5.51378 15.3785ZM5.51378 14.4541C6.06224 14.4541 6.59838 14.6167 7.05441 14.9214C7.51043 15.2262 7.86586 15.6592 8.07575 16.166C8.28564 16.6727 8.34055 17.2302 8.23355 17.7682C8.12655 18.3061 7.86245 18.8002 7.47463 19.188C7.08681 19.5758 6.5927 19.8399 6.05478 19.9469C5.51686 20.0539 4.95929 19.999 4.45258 19.7891C3.94587 19.5792 3.51277 19.2238 3.20807 18.7678C2.90336 18.3118 2.74072 17.7756 2.74072 17.2272C2.74072 16.4917 3.03288 15.7864 3.55293 15.2663C4.07298 14.7463 4.77832 14.4541 5.51378 14.4541Z"
      fill="white"
    />
    <path
      d="M5.51378 5.21049C5.14814 5.21049 4.79071 5.31891 4.48669 5.52205C4.18268 5.72519 3.94572 6.01391 3.8058 6.35172C3.66588 6.68953 3.62926 7.06124 3.7006 7.41985C3.77193 7.77847 3.948 8.10788 4.20655 8.36642C4.46509 8.62497 4.7945 8.80104 5.15312 8.87237C5.51173 8.94371 5.88344 8.90709 6.22125 8.76717C6.55905 8.62725 6.84778 8.39029 7.05092 8.08627C7.25406 7.78226 7.36248 7.42483 7.36248 7.05919C7.36248 6.56888 7.16771 6.09866 6.82101 5.75196C6.47431 5.40526 6.00409 5.21049 5.51378 5.21049ZM5.51378 4.28613C6.06224 4.28613 6.59838 4.44877 7.05441 4.75348C7.51043 5.05818 7.86586 5.49128 8.07575 5.99799C8.28564 6.5047 8.34055 7.06227 8.23355 7.60019C8.12655 8.13811 7.86245 8.63222 7.47463 9.02004C7.08681 9.40786 6.5927 9.67196 6.05478 9.77896C5.51686 9.88596 4.95929 9.83105 4.45258 9.62116C3.94587 9.41127 3.51277 9.05584 3.20807 8.59982C2.90336 8.14379 2.74072 7.60765 2.74072 7.05919C2.74072 6.32373 3.03288 5.61839 3.55293 5.09834C4.07298 4.57829 4.77832 4.28613 5.51378 4.28613Z"
      fill="white"
    />
    <path
      d="M18.4547 15.3785C18.0891 15.3785 17.7316 15.4869 17.4276 15.69C17.1236 15.8932 16.8866 16.1819 16.7467 16.5197C16.6068 16.8575 16.5702 17.2292 16.6415 17.5878C16.7128 17.9464 16.8889 18.2758 17.1475 18.5344C17.406 18.7929 17.7354 18.969 18.094 19.0403C18.4526 19.1117 18.8244 19.0751 19.1622 18.9351C19.5 18.7952 19.7887 18.5583 19.9918 18.2542C20.195 17.9502 20.3034 17.5928 20.3034 17.2272C20.3034 16.7369 20.1086 16.2666 19.7619 15.9199C19.4152 15.5732 18.945 15.3785 18.4547 15.3785ZM18.4547 14.4541C19.0032 14.4541 19.5393 14.6167 19.9953 14.9214C20.4514 15.2262 20.8068 15.6592 21.0167 16.166C21.2266 16.6727 21.2815 17.2302 21.1745 17.7682C21.0675 18.3061 20.8034 18.8002 20.4155 19.188C20.0277 19.5758 19.5336 19.8399 18.9957 19.9469C18.4578 20.0539 17.9002 19.999 17.3935 19.7891C16.8868 19.5792 16.4537 19.2238 16.149 18.7678C15.8443 18.3118 15.6816 17.7756 15.6816 17.2272C15.6816 16.4917 15.9738 15.7864 16.4939 15.2663C17.0139 14.7463 17.7192 14.4541 18.4547 14.4541Z"
      fill="white"
    />
    <path
      d="M18.4547 9.83225C19.9862 9.83225 21.2278 8.59071 21.2278 7.05919C21.2278 5.52767 19.9862 4.28613 18.4547 4.28613C16.9232 4.28613 15.6816 5.52767 15.6816 7.05919C15.6816 8.59071 16.9232 9.83225 18.4547 9.83225Z"
      fill="white"
    />
    <path
      d="M18.4547 5.21049C18.0891 5.21049 17.7316 5.31891 17.4276 5.52205C17.1236 5.72519 16.8866 6.01391 16.7467 6.35172C16.6068 6.68953 16.5702 7.06124 16.6415 7.41985C16.7128 7.77847 16.8889 8.10788 17.1475 8.36642C17.406 8.62497 17.7354 8.80104 18.094 8.87237C18.4526 8.94371 18.8244 8.90709 19.1622 8.76717C19.5 8.62725 19.7887 8.39029 19.9918 8.08627C20.195 7.78226 20.3034 7.42483 20.3034 7.05919C20.3034 6.56888 20.1086 6.09866 19.7619 5.75196C19.4152 5.40526 18.945 5.21049 18.4547 5.21049ZM18.4547 4.28613C19.0032 4.28613 19.5393 4.44877 19.9953 4.75348C20.4514 5.05818 20.8068 5.49128 21.0167 5.99799C21.2266 6.5047 21.2815 7.06227 21.1745 7.60019C21.0675 8.13811 20.8034 8.63222 20.4155 9.02004C20.0277 9.40786 19.5336 9.67196 18.9957 9.77896C18.4578 9.88596 17.9002 9.83105 17.3935 9.62116C16.8868 9.41127 16.4537 9.05584 16.149 8.59982C15.8443 8.14379 15.6816 7.60765 15.6816 7.05919C15.6816 6.32373 15.9738 5.61839 16.4939 5.09834C17.0139 4.57829 17.7192 4.28613 18.4547 4.28613Z"
      fill="white"
    />
    <path
      d="M8.1582 17.954V17.0296C9.68616 17.0296 10.8971 14.3231 11.9647 11.9355C13.146 9.29463 14.2608 6.7998 16.0605 6.7998V7.72416C14.8588 7.72416 13.8171 10.0572 12.8086 12.3127C11.5681 15.0857 10.2861 17.954 8.1582 17.954Z"
      fill="white"
    />
  </svg>
);

export default memo(MenuAiTrainingIcon);
