import { SvgIcon } from "@mui/material";
import { memo } from "preact/compat";

const MenuKnowledgeManagerIcon = (props) => (
  // <SvgIcon {...props}>
  //   <svg
  //     id='Menu_KM'
  //     data-name='Menu KM'
  //     xmlns='http://www.w3.org/2000/svg'
  //     width='24'
  //     height='24'
  //     viewBox='0 0 24 24'>
  //     <path id='Path_926' data-name='Path 926' d='M0,0H24V24H0Z' fill='none' />
  //     <path
  //       id='Path_927'
  //       data-name='Path 927'
  //       d='M19,19.5H5A2.5,2.5,0,0,1,2.5,17V6A2.5,2.5,0,0,1,5,3.5H9a.5.5,0,0,1,.354.146L12.207,6.5H19A2.5,2.5,0,0,1,21.5,9v8A2.5,2.5,0,0,1,19,19.5ZM5,4.5A1.5,1.5,0,0,0,3.5,6V17A1.5,1.5,0,0,0,5,18.5H19A1.5,1.5,0,0,0,20.5,17V9A1.5,1.5,0,0,0,19,7.5H12a.5.5,0,0,1-.354-.146L8.793,4.5Z'
  //       fill='#fff'
  //     />
  //     <path
  //       id='Line_158'
  //       data-name='Line 158'
  //       d='M0,6.5A.5.5,0,0,1-.5,6V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V6A.5.5,0,0,1,0,6.5Z'
  //       transform='translate(12 10)'
  //       fill='#fff'
  //     />
  //     <path
  //       id='Line_159'
  //       data-name='Line 159'
  //       d='M6,.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H6a.5.5,0,0,1,.5.5A.5.5,0,0,1,6,.5Z'
  //       transform='translate(9 13)'
  //       fill='#fff'
  //     />
  //   </svg>
  // </SvgIcon>
  <svg
    width="35"
    height="35"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6316 19H5.36842C4.74028 19 4.13786 18.7531 3.69369 18.3135C3.24953 17.874 3 17.2779 3 16.6563V6.34375C3 5.72215 3.24953 5.12601 3.69369 4.68647C4.13786 4.24693 4.74028 4 5.36842 4H9.1579C9.22014 3.99989 9.2818 4.01192 9.33934 4.03541C9.39689 4.05889 9.44919 4.09337 9.49326 4.13688L12.1961 6.8125H18.6316C19.2597 6.8125 19.8621 7.05943 20.3063 7.49897C20.7505 7.93851 21 8.53465 21 9.15625V16.6563C21 17.2779 20.7505 17.874 20.3063 18.3135C19.8621 18.7531 19.2597 19 18.6316 19ZM5.36842 4.9375C4.99153 4.9375 4.63008 5.08566 4.36359 5.34938C4.09709 5.6131 3.94737 5.97079 3.94737 6.34375V16.6563C3.94737 17.0292 4.09709 17.3869 4.36359 17.6506C4.63008 17.9143 4.99153 18.0625 5.36842 18.0625H18.6316C19.0085 18.0625 19.3699 17.9143 19.6364 17.6506C19.9029 17.3869 20.0526 17.0292 20.0526 16.6563V9.15625C20.0526 8.78329 19.9029 8.4256 19.6364 8.16188C19.3699 7.89816 19.0085 7.75 18.6316 7.75H12C11.9378 7.75011 11.8761 7.73808 11.8186 7.71459C11.761 7.69111 11.7087 7.65663 11.6646 7.61313L8.96179 4.9375H5.36842Z"
      fill="white"
    />
    <path
      d="M12 16.5264C11.8674 16.5264 11.7402 16.4737 11.6464 16.3799C11.5527 16.2862 11.5 16.159 11.5 16.0264V10.0264C11.5 9.89376 11.5527 9.76658 11.6464 9.67281C11.7402 9.57905 11.8674 9.52637 12 9.52637C12.1326 9.52637 12.2598 9.57905 12.3536 9.67281C12.4473 9.76658 12.5 9.89376 12.5 10.0264V16.0264C12.5 16.159 12.4473 16.2862 12.3536 16.3799C12.2598 16.4737 12.1326 16.5264 12 16.5264Z"
      fill="white"
    />
    <path
      d="M15 13.5264H9C8.86739 13.5264 8.74021 13.4737 8.64645 13.3799C8.55268 13.2862 8.5 13.159 8.5 13.0264C8.5 12.8938 8.55268 12.7666 8.64645 12.6728C8.74021 12.579 8.86739 12.5264 9 12.5264H15C15.1326 12.5264 15.2598 12.579 15.3536 12.6728C15.4473 12.7666 15.5 12.8938 15.5 13.0264C15.5 13.159 15.4473 13.2862 15.3536 13.3799C15.2598 13.4737 15.1326 13.5264 15 13.5264Z"
      fill="white"
    />
  </svg>
);

export default memo(MenuKnowledgeManagerIcon);
