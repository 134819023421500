import {
  IUserWorkspace,
  UpdateAddWorkspaceFromAdminRequest,
  UpdateRemoveWorkspaceFromAdminRequest,
  UserWorkspace,
  WorkspaceOnlyResponse,
} from "../models/user-workspace";
import { IResponse } from "../models/http";
import { getAuthHost } from "../configs/env";
import { Roles } from "../models/roles";

export const getUserWorkspace = async (
  userId: string,
  userIdToken: string
): Promise<IUserWorkspace> => {
  const url = `${getAuthHost()}/user-workspace/${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userIdToken}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUserWorkspace> = await res.json();

  // //new user registered with workspaceId
  // if(userResp.data?.workspaceId){
  //   return  userResp.data;
  // }
  
  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return new UserWorkspace(userResp.data);
  }
};

export const getUserByEmailFromWorkspaceAdmin = async (
  email: string,
  workspaceId: string,
  userId: string,
  token: string
): Promise<IUserWorkspace> => {
  const url = `${getAuthHost()}/user-workspace/${workspaceId}/email/${email}?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUserWorkspace> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

export const addFromWorkspaceAdmin = async (
  workspaceId: string,
  userId: string,
  addToUserId: string,
  role: Roles,
  token: string
): Promise<boolean> => {
  var url = new URL(
    `${getAuthHost()}/user-workspace/${userId}/add-workspace-from-admin`
  );

  const reqObj: UpdateAddWorkspaceFromAdminRequest = {
    workspaceId,
    userId,
    addToUserId,
    role,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<boolean> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const removeFromWorkspaceAdmin = async (
  workspaceId: string,
  userId: string,
  removeToUserId: string,
  token: string
): Promise<boolean> => {
  var url = new URL(
    `${getAuthHost()}/user-workspace/${userId}/remove-workspace-from-admin`
  );

  const reqObj: UpdateRemoveWorkspaceFromAdminRequest = {
    workspaceId,
    userId,
    removeToUserId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<boolean> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const removeFromKorraAdmin = async (
  workspaceId: string,
  userId: string,
  removeToUserId: string,
  token: string
): Promise<boolean> => {
  var url = new URL(
    `${getAuthHost()}/user-workspace/${userId}/remove-workspace-from-korra-admin`
  );

  const reqObj: UpdateRemoveWorkspaceFromAdminRequest = {
    workspaceId,
    userId,
    removeToUserId,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<boolean> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

export const getUsersListWorkspaceAdmin = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<IUserWorkspace[]> => {
  const url = `${getAuthHost()}/user-workspace/${workspaceId}/users?userId=${userId}`;

  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUserWorkspace[]> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

/**
 * get all users for a workspace, no need to authorise
 * @param workspaceId
 * @param userId
 * @param token
 * @returns
 */
export const getUsersListFromKorraAdmin = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<IUserWorkspace[]> => {
  const url = `${getAuthHost()}/user-workspace/${workspaceId}/users-by-korra-admin?userId=${userId}`;

  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<IUserWorkspace[]> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

/**
 * add a new user to auth0 based on workspace
 * @param workspaceId
 * @param email
 * @param password
 * @returns
 */
export const createAuth0User = async (
  workspaceId: string,
  email: string,
  password: string
) => {
  var url = new URL(`${getAuthHost()}/users/identity/auth0`);
  const reqObj: {
    email: string;
    password: string;
    workspaceId: string;
  } = {
    email,
    password,
    workspaceId,
  };
  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });
  if (res.status >= 400) {
    // get the json from response
    const userResp: IResponse<any> = await res.json();
    throw new Error("Error creating user: " + userResp.error?.message);
  }
};

/**
 * add a new role to user. no need to authorise addToUserId
 * @param workspaceId
 * @param userId
 * @param addToUserId
 * @param role
 * @param token
 * @returns
 */
export const addFromKorraAdmin = async (
  workspaceId: string,
  userId: string,
  addToUserId: string,
  role: Roles,
  token: string
): Promise<boolean> => {
  var url = new URL(
    `${getAuthHost()}/user-workspace/${userId}/add-workspace-from-korra-admin`
  );

  const reqObj: UpdateAddWorkspaceFromAdminRequest = {
    workspaceId,
    userId,
    addToUserId,
    role,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "PUT",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const updateResp: IResponse<boolean> = await res.json();

  if (updateResp.error) {
    throw new Error(updateResp.error.message);
  } else {
    return updateResp.data;
  }
};

/**
 * get all workspaces for a user
 * @param workspaceId
 * @param workspacesIds
 * @param userId
 * @param token
 * @returns
 */
export const getAdminWorkspacesList = async (
  workspaceId: string,
  workspacesIds: string,
  userId: string,
  token: string
): Promise<WorkspaceOnlyResponse[]> => {
  const url = `${getAuthHost()}/user-workspace/${workspaceId}/workspaces?ids=${workspacesIds}&userId=${userId}`;

  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<WorkspaceOnlyResponse[]> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};

/**
 * get all workspaces for a user, no need to authorise
 * @param workspaceId
 * @param workspacesIds
 * @param userId
 * @param token
 * @returns
 */
export const getAdminWorkspacesListByKorraAdmin = async (
  workspaceId: string,
  userId: string,
  token: string
): Promise<WorkspaceOnlyResponse[]> => {
  const url = `${getAuthHost()}/user-workspace/${workspaceId}/workspaces-by-korra-admin?userId=${userId}`;

  const res = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const userResp: IResponse<WorkspaceOnlyResponse[]> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};
