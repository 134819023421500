import { h } from "preact";
import styles from "./styles.module.scss";
import ModalWindow from "../ModalWindow/ModalWindow";
import {useWorkspaceContext} from "../../context/WorkspaceContext";
import {getPricingTableId, getStripePublicKey} from "../../configs/env";

const tableId = getPricingTableId();
const stripePublicKey = getStripePublicKey();

export function PricingTable({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) {
  const { workspaceId } = useWorkspaceContext();

  function stripeEmbed() {
    return {
      __html: `
    <stripe-pricing-table
        client-reference-id="${workspaceId}"
        pricing-table-id="${tableId}"
        publishable-key="${stripePublicKey}">
    </stripe-pricing-table>`,
    };
    /*
    return {
      __html: `
    <stripe-pricing-table
        client-reference-id="${workspaceId}"
        pricing-table-id="prctbl_1OnhCQAr0fI65Ddn6YUlu8G1"
        publishable-key="pk_live_51NaGjcAr0fI65Ddnv5JqpZaVdbhl6ZFWIa3v7e6gRiKv7H9cw1q3TA1VP5Lm2b9EPFvB9RunmGbw5EcZ0nw44pz000Xy8UCZRZ">
    </stripe-pricing-table>`,
    };
    */
  }

  return (
    <ModalWindow close={onClose} isVisibleModal={isVisible}>
      <div className={styles.modal__container}>
        <div dangerouslySetInnerHTML={stripeEmbed()} />
      </div>
    </ModalWindow>
  );
}
