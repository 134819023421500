import { FunctionalComponent, h, Fragment } from "preact";
import UserAuthProvider from "../context/UserAuthContext";
import SnackBarProvider from "../context/SnackBarContext";
import { SnackbarProvider } from "notistack";
import { Auth0Provider } from "@auth0/auth0-react";

import AppRouter from "./router/router";
import {
  getAuth0Audience,
  getAuth0ClientId,
  getAuth0Domain,
  getEditorHost,
  get_IS_EDGE,
} from "../configs/env";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { fetchSSOConfig } from "../api/sso-api";
import FileUploadProvider from "../context/fileUploadContext";
import SelectedKappProvider from "../context/SelectedKappContext";
import SwitchPortalContextProvider from "../context/SwitchPortalContext";

function isNonSSOEditor() {
  const nonSSODomains = [
    "editor1.korra.ai",
    "editor2.korra.ai",
    "editor.korra.ai",
    "editor-eu1.korra.ai",
    "editor-eu2.korra.ai",
    "editor-eu.korra.ai",
    "editor-eu-staging.korra.ai",
    "editor-dev.korra.ai",
    "editor-dev2.korra.ai",
    "editor-dev3.korra.ai",
    "editor-dev4.korra.ai",
    "editor-dev5.korra.ai",
    "editor-local.korra.ai",
    "editor-local.korra.ai:8080",
  ];

  return nonSSODomains.includes(window.location.host);
}

const App: FunctionalComponent = () => {
  const [isReady, setIsReady] = useState(isNonSSOEditor());
  const [auth0Domain, setAuth0Domain] = useState(getAuth0Domain());
  const [auth0ClientId, setAuth0ClientId] = useState(getAuth0ClientId());
  const [auth0Audience, setAuth0Audience] = useState(getAuth0Audience());
  const [isEdge, setIsEdge] = useState(get_IS_EDGE());

  useEffect(() => {
    if (isEdge) {
      return;
    }
    if (isReady) {
      return;
    }

    fetchSSOConfig({
      editorDomain: window.location.host,
    })
      .then((data) => {
        if (!data) {
          setIsReady(true);
          return;
        }
        setAuth0Domain(data?.domain);
        setAuth0ClientId(data?.clientId);
        setAuth0Audience(data?.audience);
        setIsReady(true);
      })
      .catch(() => {
        setIsReady(true);
      });
  }, [isReady]);
  return (
    <div id="app">
      <SnackbarProvider>
        {!isEdge ? (
          <GoogleOAuthProvider clientId="864690442768-tcge6fi58ohce2kbm667cjpide1rsjeu.apps.googleusercontent.com">
            {isReady && (
              <Auth0Provider
                domain={auth0Domain}
                clientId={auth0ClientId}
                authorizationParams={{
                  redirect_uri: `${getEditorHost()}/onboarding`,
                  audience: `https://${auth0Audience}/api/v2/`,
                }}
              >
                <SwitchPortalContextProvider>
                <SelectedKappProvider>
                  <FileUploadProvider>
                    <SnackBarProvider>
                      <UserAuthProvider>
                        <AppRouter />
                      </UserAuthProvider>
                    </SnackBarProvider>
                  </FileUploadProvider>
                </SelectedKappProvider>
                </SwitchPortalContextProvider>
              </Auth0Provider>
            )}
          </GoogleOAuthProvider>
        ) : (
          <SwitchPortalContextProvider>
          <SelectedKappProvider>
            <FileUploadProvider>
              <SnackbarProvider>
                <GoogleOAuthProvider clientId="864690442768-tcge6fi58ohce2kbm667cjpide1rsjeu.apps.googleusercontent.com">
                  {isReady && (
                    <Auth0Provider
                      domain={auth0Domain}
                      clientId={auth0ClientId}
                      authorizationParams={{
                        redirect_uri: `${getEditorHost()}/`,
                        audience: `https://${auth0Audience}/api/v2/`,
                      }}
                    >
                      <SnackBarProvider>
                        <UserAuthProvider>
                          <AppRouter />
                        </UserAuthProvider>
                      </SnackBarProvider>
                    </Auth0Provider>
                  )}
                </GoogleOAuthProvider>
              </SnackbarProvider>
            </FileUploadProvider>
          </SelectedKappProvider>
          </SwitchPortalContextProvider>
        )}
      </SnackbarProvider>
    </div>
  );
  // return (
  //   <div id="app">
  //     <SnackbarProvider>
  //     {isEdge ? (
  //       <GoogleOAuthProvider clientId="864690442768-tcge6fi58ohce2kbm667cjpide1rsjeu.apps.googleusercontent.com">
  //         <SelectedKappProvider>
  //           <FileUploadProvider>
  //             <SnackbarProvider>
  //               {isReady && (
  //                 <Auth0Provider
  //                   domain={auth0Domain}
  //                   clientId={auth0ClientId}
  //                   authorizationParams={{
  //                     redirect_uri: `${getEditorHost()}/onboarding`,
  //                     audience: `https://${auth0Audience}/api/v2/`,
  //                   }}
  //                 >
  //                   <SnackBarProvider>
  //                     <UserAuthProvider>
  //                       <AppRouter />
  //                     </UserAuthProvider>
  //                   </SnackBarProvider>
  //                 </Auth0Provider>
  //               )}
  //             </SnackbarProvider>
  //           </FileUploadProvider>
  //         </SelectedKappProvider>
  //       </GoogleOAuthProvider>
  //     ) : (
  //       <SelectedKappProvider>
  //         <FileUploadProvider>
  //           <SnackBarProvider>
  //             <UserAuthProvider>
  //               <AppRouter />
  //             </UserAuthProvider>
  //           </SnackBarProvider>
  //         </FileUploadProvider>
  //       </SelectedKappProvider>

  //     )}
  //     </SnackbarProvider>
  //   </div>
  // );
};

export default App;
