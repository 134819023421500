import { SvgIcon } from "@mui/material";
import { memo } from "preact/compat";

const MenuAnalyticsIcon = (props) => (
  // <SvgIcon {...props}>
  //   <svg
  //     id='Menu_Analytics'
  //     data-name='Menu Analytics'
  //     xmlns='http://www.w3.org/2000/svg'
  //     width='24'
  //     height='24'
  //     viewBox='0 0 24 24'>
  //     <path id='Path_922' data-name='Path 922' d='M0,0H24V24H0Z' fill='none' />
  //     <path
  //       id='Rectangle_2165'
  //       data-name='Rectangle 2165'
  //       d='M1.105-.5h1.79A1.607,1.607,0,0,1,4.5,1.1V10.9a1.607,1.607,0,0,1-1.605,1.6H1.105A1.607,1.607,0,0,1-.5,10.9V1.1A1.607,1.607,0,0,1,1.105-.5Zm1.79,12a.606.606,0,0,0,.605-.6V1.1A.606.606,0,0,0,2.895.5H1.105A.606.606,0,0,0,.5,1.1V10.9a.606.606,0,0,0,.605.6Z'
  //       transform='translate(10 9)'
  //       fill='#fff'
  //     />
  //     <path
  //       id='Rectangle_2167'
  //       data-name='Rectangle 2167'
  //       d='M1.105-.5h1.79A1.607,1.607,0,0,1,4.5,1.105v4.79A1.607,1.607,0,0,1,2.895,7.5H1.105A1.607,1.607,0,0,1-.5,5.895V1.105A1.607,1.607,0,0,1,1.105-.5Zm1.79,7A.606.606,0,0,0,3.5,5.895V1.105A.606.606,0,0,0,2.895.5H1.105A.606.606,0,0,0,.5,1.105v4.79a.606.606,0,0,0,.605.605Z'
  //       transform='translate(3 14)'
  //       fill='#fff'
  //     />
  //     <path
  //       id='Rectangle_2166'
  //       data-name='Rectangle 2166'
  //       d='M1.105-.5h1.79A1.607,1.607,0,0,1,4.5,1.1V16.9a1.607,1.607,0,0,1-1.605,1.6H1.105A1.607,1.607,0,0,1-.5,16.9V1.1A1.607,1.607,0,0,1,1.105-.5Zm1.79,18a.606.606,0,0,0,.605-.6V1.1A.606.606,0,0,0,2.895.5H1.105A.606.606,0,0,0,.5,1.1V16.9a.606.606,0,0,0,.605.6Z'
  //       transform='translate(17 3)'
  //       fill='#fff'
  //     />
  //   </svg>
  // </SvgIcon>

  <svg
    width="35"
    height="35"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9729 8.7373H12.5274C12.8962 8.73776 13.2498 8.88416 13.5109 9.14451C13.7721 9.40487 13.9196 9.75801 13.9212 10.1268V18.6373C13.9196 19.0061 13.7721 19.3592 13.5109 19.6196C13.2498 19.8799 12.8962 20.0263 12.5274 20.0268H10.9729C10.6041 20.0263 10.2505 19.8799 9.98938 19.6196C9.72821 19.3592 9.58071 19.0061 9.5791 18.6373V10.1268C9.58071 9.75801 9.72821 9.40487 9.98938 9.14451C10.2505 8.88416 10.6041 8.73776 10.9729 8.7373ZM12.5274 19.1584C12.6659 19.1581 12.7988 19.1033 12.8971 19.0058C12.9955 18.9082 13.0514 18.7758 13.0528 18.6373V10.1268C13.0514 9.98826 12.9955 9.85587 12.8971 9.75832C12.7988 9.66078 12.6659 9.60595 12.5274 9.60573H10.9729C10.8344 9.60595 10.7015 9.66078 10.6032 9.75832C10.5048 9.85587 10.4489 9.98826 10.4475 10.1268V18.6373C10.4489 18.7758 10.5048 18.9082 10.6032 19.0058C10.7015 19.1033 10.8344 19.1581 10.9729 19.1584H12.5274Z"
      fill="white"
    />
    <path
      d="M4.89382 13.0791H6.44829C6.81781 13.0796 7.17207 13.2266 7.43336 13.4878C7.69465 13.7491 7.84165 14.1034 7.84211 14.4729V18.6327C7.84165 19.0022 7.69465 19.3564 7.43336 19.6177C7.17207 19.879 6.81781 20.026 6.44829 20.0265H4.89382C4.52429 20.026 4.17004 19.879 3.90875 19.6177C3.64746 19.3564 3.50046 19.0022 3.5 18.6327V14.4729C3.50046 14.1034 3.64746 13.7491 3.90875 13.4878C4.17004 13.2266 4.52429 13.0796 4.89382 13.0791ZM6.44829 19.158C6.58756 19.1578 6.72107 19.1024 6.81955 19.0039C6.91803 18.9054 6.97345 18.7719 6.97368 18.6327V14.4729C6.97345 14.3336 6.91803 14.2001 6.81955 14.1017C6.72107 14.0032 6.58756 13.9478 6.44829 13.9475H4.89382C4.75454 13.9478 4.62104 14.0032 4.52256 14.1017C4.42408 14.2001 4.36865 14.3336 4.36842 14.4729V18.6327C4.36865 18.7719 4.42408 18.9054 4.52256 19.0039C4.62104 19.1024 4.75454 19.1578 4.89382 19.158H6.44829Z"
      fill="white"
    />
    <path
      d="M17.0515 3.52637H18.606C18.9748 3.52682 19.3284 3.67322 19.5895 3.93358C19.8507 4.19393 19.9982 4.54707 19.9998 4.91584V18.6369C19.9982 19.0057 19.8507 19.3588 19.5895 19.6192C19.3284 19.8795 18.9748 20.0259 18.606 20.0264H17.0515C16.6828 20.0259 16.3292 19.8795 16.068 19.6192C15.8068 19.3588 15.6593 19.0057 15.6577 18.6369V4.91584C15.6593 4.54707 15.8068 4.19393 16.068 3.93358C16.3292 3.67322 16.6828 3.52682 17.0515 3.52637ZM18.606 19.1579C18.7445 19.1577 18.8774 19.1029 18.9757 19.0053C19.0741 18.9078 19.13 18.7754 19.1314 18.6369V4.91584C19.13 4.77732 19.0741 4.64493 18.9757 4.54739C18.8774 4.44984 18.7445 4.39501 18.606 4.39479H17.0515C16.913 4.39501 16.7802 4.44984 16.6818 4.54739C16.5834 4.64493 16.5275 4.77732 16.5261 4.91584V18.6369C16.5275 18.7754 16.5834 18.9078 16.6818 19.0053C16.7802 19.1029 16.913 19.1577 17.0515 19.1579H18.606Z"
      fill="white"
    />
  </svg>
);

export default memo(MenuAnalyticsIcon);
