import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useState } from "preact/hooks";
import { Color } from "@material-ui/lab";
import { Alert, Snackbar } from "@mui/material";

export interface ISnackBarContextActions {
  showSnackBar: (text: string, typeColor: Color, vertical?: "top" | "bottom", horizontal?:  "center" | "left" | "right") => void;
};

export const SnackBarContext = createContext<ISnackBarContextActions>(null);

export const useSnackBar = () => {
  return useContext(SnackBarContext);
};


const SnackBarProvider: FunctionalComponent = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [typeColor, setTypeColor] = useState<Color>('info');
  const [vertical, setVertical] = useState<"top" | "bottom">('top');
  const [horizontal, setHorizontal] = useState< "center" | "left" | "right">('center');

  const showSnackBar = (text: string, color: Color, vertical?: "top" | "bottom", horizontal?: "center" | "left" | "right") => {
    if (vertical) setVertical(vertical);
    if (horizontal) setHorizontal(horizontal);
    setMessage(text);
    setTypeColor(color);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTypeColor('info');
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={typeColor}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
