import { getAiHost } from "../configs/env";
import { IResponse } from "../models/http";

//const host = "http://localhost:8000"; //getAiHost()
const host = getAiHost();

interface OnboardingCompletion {
  completed: boolean;
}

export const getOnboardingCompletionStatus = async (
  userId: string,
  token: string
): Promise<OnboardingCompletion> => {
  const url = `${host}/onboarding/completion?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const response: IResponse<OnboardingCompletion> = await res.json();

  return response.data;
};

export const postOnboardingCompletion = async (
  userId: string,
  token: string,
  payload: {
    first_name: string;
    last_name: string;
    email: string;
  }
): Promise<OnboardingCompletion> => {
  const url = `${host}/onboarding/completion?userId=${userId}`;

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "POST",
    mode: "cors",
    body: JSON.stringify(payload),
  });

  const response: OnboardingCompletion = await res.json();

  return response;
};
