import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useState } from "preact/hooks";
import Uppy from "@uppy/core";

export interface FileUploadContextActions {
  handleAddUppy: (uppy: Uppy) => void;
  uppyInst: Uppy | null;
}

export const fileProgressContext =
  createContext<FileUploadContextActions>(null);

export const useFileProgressContext = () => {
  return useContext(fileProgressContext);
};

const FileUploadProvider: FunctionalComponent = ({ children }) => {
  const [uppyInst, setUppyInst] = useState<any>();

  const handleAddUppy = (uppy: Uppy) => {
    setUppyInst(uppy);
  };

  return (
    <fileProgressContext.Provider
      value={{ uppyInst: uppyInst, handleAddUppy: handleAddUppy }}
    >
      {children}
    </fileProgressContext.Provider>
  );
};

export default FileUploadProvider;
